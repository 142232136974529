<template>
  <v-container class="my-6">
    <v-row>
      <v-col cols="12" md="6" lg="6" xl="6">
        <div class="product-slider">
          <VueSlickCarousel
            ref="c1"
            :fade="true"
            :asNavFor="$refs.c2"
            :focusOnSelect="true"
          >
            <div v-for="(item, index) in flashDeals" :key="index">
              <v-img class="mx-auto" width="300" height="auto" :src="item.img"></v-img>
            </div>
          </VueSlickCarousel>
          <VueSlickCarousel
            ref="c2"
            class="product-slider-click"
            :asNavFor="$refs.c1"
            :slidesToShow="3"
            :focusOnSelect="true"
          >
            <div v-for="(item, index) in flashDeals" :key="index">
              <v-avatar class="white rounded mr-3" tile size="78">
                <img :src="item.img" alt="" />
              </v-avatar>
            </div>
          </VueSlickCarousel>
        </div>
      </v-col>
      <!-- <v-col cols="12" lg="6" align="center">
            <v-img max-width="300" src="@/assets/images/products/headphone.png"></v-img>
            <div class="d-flex justify-center mt-12">
                <v-avatar tile class="rounded-lg border-primary bg-white me-3" size="70" >
                    <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">
                </v-avatar>
                <v-avatar tile class="rounded-lg border-grey bg-white me-3" size="70" >
                    <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">
                </v-avatar>
                <v-avatar tile class="rounded-lg border-grey bg-white" size="70" >
                    <img class="pa-3 " src="@/assets/images/products/headphone.png" alt="">
                </v-avatar>
            </div>
          </v-col> -->
      <v-col cols="12" lg="6">
        <h1 class="mb-4">Mi Note 11 Pro</h1>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">Brand:</p>
          <p class="font-600 mb-0">Ziaomi</p>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3 mb-0">Rated:</p>
          <div class="d-flex align-center justify-center align-center mb-1">
            <v-rating
              :value="4.5"
              color="amber"
              dense
              half-increments
              readonly
              size="16"
            ></v-rating>

            <div class="grey--text text--darken-4 ml-1">(49)</div>
          </div>
        </div>
        <div class="mb-4">
          <h2 class="primary--text">$1135.00</h2>
          <p class="">Stock Available</p>
        </div>
        <div class="mb-6">
          <v-btn
            @click="addCartx(singleProductData)"
            color="primary"
            class="text-capitalize mb-3"
            :class="singleProductData.qty <= 0 ? 'd-block' : 'd-none'"
          >
            Add to Cart
          </v-btn>
          <div
            class="align-center"
            :class="singleProductData.qty <= 0 ? 'd-none' : 'd-flex'"
          >
            <v-btn
              color="primary"
              outlined
              icon
              tile
              class="br-8"
              @click="removeCartx(singleProductData)"
            >
              <v-icon>mdi-minus</v-icon>
            </v-btn>
            <h3 class="mx-5">{{ singleProductData.qty }}</h3>
            <v-btn
              color="primary"
              outlined
              icon
              tile
              class="br-8"
              @click="addCartx(singleProductData)"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </div>
        <div class="d-flex mb-4">
          <p class="grey--text text--grey-lighten-1 me-3">Sold By:</p>
          <p class="font-600">Mobile Store</p>
        </div>
      </v-col>
      <v-col cols="12" class="mt-12">
        <v-tabs v-model="tab" background-color="transparent" color="primary" class="">
          <v-tab class="font-600 text-capitalize">Description</v-tab>
          <v-tab class="font-600 text-capitalize">Specifications</v-tab>
        </v-tabs>

        <v-tabs-items class="transparent my-6 pb-6" v-model="tab">
          <v-tab-item>
            Slim Stereo Splitter Cable - 3.5mm Male to 2x 3.5mm Female
          </v-tab-item>

          <v-tab-item>
            <v-col cols="12" class="pt-2">
              <h4 class="mb-4">General Information:</h4>
              <v-row>
                <v-col cols="2" class="font-600">Packaged Quantity</v-col>
                <v-col cols="10">1</v-col>
                <v-col cols="2" class="font-600">Marketing Information</v-col>
                <v-col cols="10">
                  The MUY1MFFS Slim 3.5mm Stereo Splitter Cable/Y-cable features one 3.5mm
                  male and two 3.5mm female audio connectors, splitting a single headphone
                  jack into two.
                </v-col>
                <v-col cols="2" class="font-600">Manufacturer Website </v-col>
                <v-col cols="10">http://www.startech.com/au</v-col>
                <v-col cols="2" class="font-600">Manufacturer Part Number</v-col>
                <v-col cols="10">MUY1MFFS</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-2">
              <h4 class="mb-4">Technical Information:</h4>
              <v-row>
                <v-col cols="2" class="font-600">Cable Length</v-col>
                <v-col cols="10">20 cm</v-col>
                <v-col cols="2" class="font-600">Device Supported</v-col>
                <v-col cols="10">Audio Device</v-col>
                <v-col cols="2" class="font-600">Features</v-col>
                <v-col cols="10">Molded</v-col>
                <v-col cols="2" class="font-600">Connector on Second End Details</v-col>
                <v-col cols="10">2 x Mini-phone Stereo Audio - Female</v-col>
                <v-col cols="2" class="font-600">Connector on First Enter</v-col>
                <v-col cols="10">1 x Mini-phone Stereo Audio - Male</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-2">
              <h4 class="mb-4">Physical Characteristics:</h4>
              <v-row>
                <v-col cols="2" class="font-600">Weight (Approximate)</v-col>
                <v-col cols="10">11.3 g</v-col>
                <v-col cols="2" class="font-600">Product Color</v-col>
                <v-col cols="10">Black</v-col>
                <v-col cols="2" class="font-600">Jacket Material</v-col>
                <v-col cols="10">Polyvinyl Chloride (PVC)</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-2">
              <h4 class="mb-4">Miscellaneous:</h4>
              <v-row>
                <v-col cols="2" class="font-600">Environmental Certification</v-col>
                <v-col cols="10">RoHS</v-col>
                <v-col cols="2" class="font-600">Environmentally Friendly</v-col>
                <v-col cols="10">Yes</v-col>
              </v-row>
            </v-col>
            <v-col cols="12" class="pt-2">
              <h4 class="mb-4">Warranty:</h4>
              <v-row>
                <v-col cols="2" class="font-600">Limited Warranty</v-col>
                <v-col cols="10">Lifetime</v-col>
              </v-row>
            </v-col>
          </v-tab-item>
        </v-tabs-items>

        <h3 class="mb-6">Related Products</h3>
        <v-row>
          <v-col
            cols="12"
            sm="6"
            md="4"
            lg="3"
            xl="2"
            v-for="(item, index) in getProducts.slice(90, 94).map((n) => n)"
            :key="index"
          >
            <CardCart
              :content-img="item.img"
              :content-text="item.title"
              :counter="item.qty"
              class="m-5"
              @cartRemove="removeCart(item)"
              @cartAdd="addCart(item)"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CardCart from "@/components/cartCard/CardCart";
export default {
  components: {
    CardCart,
  },
  computed: {
    ...mapGetters(["getProducts"]),
  },
  data() {
    return {
      tab: null,
      isQuantity: 0,
      singleProductData: {
        id: 1,
        img: require("@/assets/images/products/flash-1.png"),
        title: "Sneakers",
        category: "Sneakers",
        amount: 300,
        rating: 3,
        qty: 0,
      },
      flashDeals: [
        {
          img: require("@/assets/images/products/flash-1.png"),
          name: "Sneakers",
        },
        {
          img: require("@/assets/images/products/flash-2.png"),
          name: "Watch",
        },
        {
          img: require("@/assets/images/products/flash-3.png"),
          name: "Mobile",
        },
      ],
    };
  },
  methods: {
    ...mapActions(["addCart", "removeCart"]),
    addCartx(data) {
      this.addCart(data);
    },
    removeCartx(data) {
      this.removeCart(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.product-slider {
  .product-slider-click {
    display: flex;
    justify-content: center;
    ::v-deep .slick-slide {
      width: 80px !important;
      margin-right: 16px;
      border: 1px solid rgb(218, 225, 231);
      border-radius: 5px;
    }
    ::v-deep .slick-current {
      border: 1px solid #d23f57;
      border-radius: 5px;
    }
  }
}
</style>
